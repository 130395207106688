"use client";
import React, { useState, useEffect, useRef } from "react";
import { mdiOfficeBuildingOutline } from "@mdi/js";
import { mdiNetworkStrength4Cog } from "@mdi/js";
import { mdiSmokeDetectorOutline } from "@mdi/js";
import { mdiRocketLaunch } from "@mdi/js";
import { mdiBookmarkMultiple } from "@mdi/js";
import { mdiArrowLeftThin } from '@mdi/js';
import { mdiArrowRightThin } from '@mdi/js';

import { useLanguage } from "@/context/LanguageContext";
import {translate} from "@/Hooks/translate";

import Icon from "@mdi/react";
import Link from "next/link";
import Image from "next/image";
import "./styles.css";

import Org_1 from "@/public/icons/videos-icons/org-1.svg";
import Org_2 from "@/public/icons/videos-icons/org-2.svg";
import Org_3 from "@/public/icons/videos-icons/org-3.svg";
import Org_4 from "@/public/icons/videos-icons/org-4.svg";

import Marking_1 from "@/public/icons/videos-icons/marking-1.svg";
import Marking_2 from "@/public/icons/videos-icons/marking-2.svg";
import Marking_3 from "@/public/icons/videos-icons/marking-3.svg";
import Marking_4 from "@/public/icons/videos-icons/marking-4.svg";

import Project_1 from "@/public/icons/videos-icons/project-1.svg";
import Project_2 from "@/public/icons/videos-icons/project-2.svg";
import Project_3 from "@/public/icons/videos-icons/project-3.svg";
import Project_4 from "@/public/icons/videos-icons/project-4.svg";

import Models_1 from "@/public/icons/videos-icons/models-1.svg";
import Models_2 from "@/public/icons/videos-icons/models-2.svg";
import Models_3 from "@/public/icons/videos-icons/models-3.svg";
import Models_4 from "@/public/icons/videos-icons/models-4.svg";

import Sim_1 from "@/public/icons/videos-icons/sim-1.svg";
import Sim_2 from "@/public/icons/videos-icons/sim-2.svg";
import Sim_3 from "@/public/icons/videos-icons/sim-3.svg";
import Sim_4 from "@/public/icons/videos-icons/sim-4.svg";

const CustomMenu = ({}) => {

  const {language } = useLanguage();

  const [ltr , setLtr] = useState(false);

  const [videoDuration, setVideoDuration] = useState<number>(10); // Default to 10 seconds

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    language === "en" || language === "tr" ? setLtr(true) : setLtr(false);
  } , [language])

  const [selectedItem, setSelectedItem] = useState("سازمان و دسترسی");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  //this state and useeffect below is for loading
  const [isLoading, setIsLoading] = useState(true);

  type MenuItem = string;

  const translateMenuItems = (language: string): MenuItem[] => {
    let translatedItems: MenuItem[] = [];
    if (language === 'en') {
      translatedItems = [
        "Org & Access",
        "Project",
        "Marking",
        "Models",
        "Simulation"
      ];
    } else if (language === 'fa') {
      translatedItems = [
        "سازمان و دسترسی",
        "پروژه",
        "نشانه گذاری",
        "مدل ها",
        "امکان سنجی",
      ];
    } else if (language === 'ar') {
      translatedItems = [
        "التنظيم والوصول",
        "المشروع",
        "العلامات",
        "النماذج",
        "المحاكاة"
      ];
    } else if (language === 'tr') {
      translatedItems = [
        "Org & Erişim",
        "Proje",
        "İşaretleme",
        "Modeller",
        "Simülasyon"
      ];
    }
    return translatedItems || []; // Provide a default value to avoid returning undefined
  };

  const translateItemsHeader = (language: string): MenuItem[] => {
    let translatedItems: MenuItem[] = [];
    if (language === 'en') {
      translatedItems = [
        "Ability to create organizations, teams, and manage access",
        "Ability to generate, search and manage image datasets",
        "Providing convenient and fast image tagging tools",
        "Tools and Infrastructures for Creating Accurate AI Models",
        "Ability to apply and deploy the results on the cloud server or device of your choice"
      ];
    } else if (language === 'fa') {
      translatedItems = [
        "امکان ایجاد سازمان، تیم و مدیریت دسترسی ها",
        "امکان تولید، جستجو و مدیریت مجموعه داده تصویری ",
        "ارائه ابزارهای برچسب گذاری راحت و سریع تصاویر ",
        "ابزارها و زیر ساخت هایی برای ایجاد مدل های دقیق هوش مصنوعی",
        "امکان بکارگیری و استقرار نتایج بر روی سرور ابری و یا دستگاه مورد نظر شما",
      ];
    } else if (language === 'ar') {
      translatedItems = [
        "القدرة على إنشاء المؤسسات والفرق، وإدارة الوصول",
        "القدرة على إنشاء، البحث، وإدارة مجموعات الصور",
        "توفير أدوات وسهولات سريعة ومريحة لوضع العلامات على الصور",
        "الأدوات والبنية التحتية لإنشاء نماذج AI دقيقة",
        "القدرة على تطبيق ونشر النتائج على خادم سحابي أو جهاز اختيارك"
    ];
    } else if (language === 'tr') {
      translatedItems = [
        "Kuruluşlar, ekipler oluşturma ve erişimi yönetme yeteneği",
        "Görüntü veri kümelerini oluşturma, arama ve yönetme yeteneği",
        "Hızlı ve pratik görüntü etiketleme araçları sağlama yeteneği",
        "Doğru yapay zeka modelleri oluşturmak için araçlar ve altyapılar",
        "Sonuçları istediğiniz bulut sunucusuna veya cihaza uygulama ve dağıtma yeteneği"
    ];
    }
    return translatedItems || []; // Provide a default value to avoid returning undefined
  };


  const menuItems = translateMenuItems(language);
  const itemsHeader = translateItemsHeader(language);
  
  //for handling loading when video not loaded
  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    setProgress(0); // Reset progress when switching tabs
    setIsLoading(true); // Reset loading state when switching tabs
  };


  useEffect(() => {
    if (!isLoading) {
      const autoSwitchTabs = setInterval(() => {
        setSelectedIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % menuItems.length;
          setIsLoading(true);
          return newIndex;
        });
        setProgress(0);
      }, videoDuration * 1000); // Use videoDuration in milliseconds
  
      return () => clearInterval(autoSwitchTabs);
    }
    
  }, [menuItems, isLoading, videoDuration]);

  const ResetProgress = () => {
    setProgress(0);
     if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play(); // Ensure the video restarts
      }
  }


  const getVideoSrc = (item: string , language: string) => {
      switch (item) {
        case "سازمان و دسترسی":
        case "Org & Access":
        case "التنظيم والوصول":
        case "Org & Erişim":
          return "../icons/bina/1.mp4";

        case "پروژه":
        case "Project":
        case "المشروع":
        case "Proje":
          return "../icons/bina/2.mp4";

        case "نشانه گذاری":
        case "Marking":
        case "العلامات":
        case "İşaretleme":
          return "../icons/bina/3.mp4";

        case "مدل ها":
        case "Models":
        case "النماذج":
        case "Modeller":
          return "../icons/bina/4.mp4";

        case "امکان سنجی":
        case "Simulation":
        case "المحاكاة":
        case "Simülasyon":
          return "../icons/bina/5.mp4";

        default:
          return "";
      }
  };


  const getContent = (item: string) => {
    switch (item) {
      case "سازمان و دسترسی":
      case "Org & Access":
      case "التنظيم والوصول":
      case "Org & Erişim":
        return (
          <div className="font-normal">
            <ul>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Org_1 fill="black" />
                  </svg>
                </div>
                {/* <div style={{width : "24px" , height : "24px"}}>
                  <Org_1 fill="black" />
                </div> */}
                {translate('video.tabs_text.one.li1' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Org_2 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.one.li2' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Org_3 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.one.li3' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Org_4 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.one.li4' , language)} 
              </li>
            </ul>
          </div>
        );
      case "پروژه":
      case "Project":
      case "المشروع":
      case "Proje":
        return (
          <div className="font-normal">
            <ul>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Project_1 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.two.li1' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Project_2 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.two.li2' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Project_3 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.two.li3' , language)}
              </li>
              {/* <li className="pb-2 leading-7 text-[.8rem]">{translate('video.tabs_text.two.li4' , language)}</li> */}
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Project_4 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.two.li5' , language)}
              </li>
            </ul>
          </div>
        );
      case "نشانه گذاری":
      case "Marking":
      case "العلامات":
      case "İşaretleme":
        return (
          <div className="font-normal">
            <ul>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Marking_1 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.three.li1' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Marking_2 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.three.li2' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Marking_3 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.three.li3' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Marking_4 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.three.li4' , language)}
              </li>
            </ul>
          </div>
        );
      case "مدل ها":
      case "Models":
      case "النماذج":
      case "Modeller":
        return (
          <div className="font-normal">
            <ul>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Models_1 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.four.li1' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Models_2 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.four.li2' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Models_3 fill="black" />
                  </svg>
                </div>  
                {translate('video.tabs_text.four.li3' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Models_4 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.four.li4' , language)}
              </li>
            </ul>
          </div>
        );
      case "امکان سنجی":
      case "Simulation":
      case "المحاكاة":
      case "Simülasyon":
        return (
          <div className="font-normal">
            <ul>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Sim_1 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.five.li1' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Sim_2 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.five.li2' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Sim_3 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.five.li3' , language)}
              </li>
              <li className="pb-4 leading-5 text-[.8rem] flex gap-4 items-start">
                <div style={{ width: "24px", height: "24px", objectFit: "contain", objectPosition: "center" }} className="flex items-center justify-center my-auto">
                  <svg width="20" height="20" viewBox="0 0 26 26">
                    <Sim_4 fill="black" />
                  </svg>
                </div>
                {translate('video.tabs_text.five.li4' , language)}
              </li>
            </ul>
          </div>
        );
      default:
        return null; // در صورتی که موردی با switch case مطابقت نداشته باشد
    }
  };

  const getContentLink = (item: string) => {
    switch (item) {
      case "سازمان و دسترسی":
      case "Org & Access":
      case "التنظيم والوصول":
      case "Org & Erişim":
        return (
          <div className="flex items-center pt-8 pb-4 mt-4">
            <Link className={`text-pink text-bold text-[1rem] border-b border-[#fff] absolute bottom-0 hover-effect transition-all duration-300 ease-in-out pb-2 ${ltr ? "ltr-border" : "rtl-border"}`} href="https://app.binaexperts.com" target="_blank"> 
              {translate('video.tabs_btn.one' , language)}
              {ltr ? <Icon className="inline" path={mdiArrowRightThin} size={1.2} /> : <Icon className="inline" path={mdiArrowLeftThin} size={1.2} />}
            </Link>
          </div>
        );
      case "پروژه":
      case "Project":
      case "المشروع":
      case  "Proje":
        return (
          <div className="flex items-center pt-8 pb-4 mt-4">
            <Link className={`text-pink text-bold text-[1rem] border-b border-[#fff] absolute bottom-0 hover-effect transition-all duration-300 ease-in-out pb-2 ${ltr ? "ltr-border" : "rtl-border"}`} href="https://app.binaexperts.com" target="_blank"> 
              {translate('video.tabs_btn.two' , language)}
              {ltr ? <Icon className="inline" path={mdiArrowRightThin} size={1.2} /> : <Icon className="inline" path={mdiArrowLeftThin} size={1.2} />}
            </Link>
          </div>
        );
      case "نشانه گذاری":
      case "Marking":
      case "العلامات":
      case "İşaretleme":
        return (
          <div className="flex items-center pt-8 pb-4 mt-4">
            <Link className={`text-pink text-bold text-[1rem] border-b border-[#fff] absolute bottom-0 hover-effect transition-all duration-300 ease-in-out pb-2 ${ltr ? "ltr-border" : "rtl-border"}`} href="https://app.binaexperts.com" target="_blank"> 
              {translate('video.tabs_btn.three' , language)}
              {ltr ? <Icon className="inline" path={mdiArrowRightThin} size={1.2} /> : <Icon className="inline" path={mdiArrowLeftThin} size={1.2} />}
            </Link>
          </div>
        );
      case "مدل ها":
      case "Models":
      case "النماذج":
      case "Modeller":
        return (
          <div className="flex items-center pt-10 pb-4 mt-4">
            <Link className={`text-pink text-bold text-[1rem] border-b border-[#fff] absolute bottom-0 hover-effect transition-all duration-300 ease-in-out pb-2 ${ltr ? "ltr-border" : "rtl-border"}`} href="https://app.binaexperts.com" target="_blank"> 
              {translate('video.tabs_btn.four' , language)}
              {ltr ? <Icon className="inline" path={mdiArrowRightThin} size={1.2} /> : <Icon className="inline" path={mdiArrowLeftThin} size={1.2} />}
            </Link>
          </div>
        );
      case "امکان سنجی":
      case "Simulation":
      case "المحاكاة":
      case "Simülasyon":
        return (
          <div className="flex items-center pt-8 pb-4 mt-4">
            <Link className={`text-pink text-bold text-[1rem] border-b border-[#fff] absolute bottom-0 hover-effect transition-all duration-300 ease-in-out pb-2 ${ltr ? "ltr-border" : "rtl-border"}`} href="https://app.binaexperts.com" target="_blank"> 
              {translate('video.tabs_btn.five' , language)}
              {ltr ? <Icon className="inline" path={mdiArrowRightThin} size={1.2} /> : <Icon className="inline" path={mdiArrowLeftThin} size={1.2} />}
            </Link>
          </div>
        );
      default:
        return "";
    }
  };

  const progressTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    const handleLoadedData = () => {
      setIsLoading(false); // Video is ready to play
    };

    const handleWaiting = () => {
      setIsLoading(true); // Video is buffering/loading
    };

    const handleCanPlayThrough = () => {
      setIsLoading(false); // Video can play through without buffering
    };

    const handleLoadedMetadata = () => {
      setVideoDuration(video.duration); // Set the duration when video metadata is loaded
      setProgress(0); // Reset progress when video metadata is loaded
    };

    const handleVideoEnded = () => {
      if (progressTimerRef.current) {
        clearInterval(progressTimerRef.current); // Stop progress updates
      }
      setSelectedIndex((prevIndex) => (prevIndex + 1) % menuItems.length); // Switch to the next tab
      setIsLoading(true); // Prepare for the next video to load
    };

    // Attach event listeners to the video element
    video.addEventListener("loadeddata", handleLoadedData);
    video.addEventListener("waiting", handleWaiting);
    video.addEventListener("canplaythrough", handleCanPlayThrough);
    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("ended", handleVideoEnded);

    if (!isLoading) {
      progressTimerRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 100 / (videoDuration * 10); // Adjust increment based on video duration
          return newProgress >= 100 ? 100 : newProgress; // Cap at 100%
        });
      }, 100); // Update progress every 100ms
    }

    return () => {
      if (progressTimerRef.current) {
        clearInterval(progressTimerRef.current); // Clear the progress timer on cleanup
      }
      video.removeEventListener("loadeddata", handleLoadedData);
      video.removeEventListener("waiting", handleWaiting);
      video.removeEventListener("canplaythrough", handleCanPlayThrough);
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("ended", handleVideoEnded);
    };
  }, [selectedIndex, videoDuration, isLoading, menuItems.length]);


  return (
    <div className="menu-container flex justify-center items-center py-12 mx-auto">
      <div className="max-w-screen-xl w-full flex flex-wrap items-center mx-auto pt-1 pb-1 px-8 justify-center main-box z-[90]">
        <div className="relative rounded-lg  w-full boxy bg-[#F3F4F6]" style={{backgroundColor : "#F3F4F6"}}>
          <div className="flex justify-center flex-col ">
            <div className="menu flex  justify-center flex-col bg-color py-3 ">
              <div className="flex justify-center gap-6 main-tab-parent">
                {menuItems.map((item, index) => (
                  <div key={index} className=" " onClick={() => ResetProgress()}>
                    <div className="button-wrapper main-box-tittle">
                      <button
                        className={`flex p-4 pb-3 justify-center text-md font-bold border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 button tabs-header`}
                        onClick={() => {
                          setSelectedItem(item);
                          handleTabChange(index);
                          ResetProgress();
                        }}
                        style={{
                          color: selectedIndex === index ? "#11B78F" : "",
                        }}
                      >
                        
                        <Icon
                        style={{marginLeft : "0.3rem" , marginRight : "0.3rem"}}
                        className="icons mt-[3px]"
                          path={
                            index === 0
                              ? mdiOfficeBuildingOutline
                              : index === 1
                              ? mdiNetworkStrength4Cog
                              : index === 2
                              ? mdiBookmarkMultiple
                              : index === 3
                              ? mdiSmokeDetectorOutline
                              : mdiRocketLaunch
                          }
                          size={1}
                        />
                        {item}{" "}
                      </button>

                      <div className={`progress-barr ${ltr ? "ltr-progress-barr" : null}`}>
                      {selectedIndex === index && (
                        <progress
                          style={ltr ? {marginRight : "0" , marginLeft : "20px"} : {}}
                          className="progress-bar h-[2px] mr-[20px]"
                          value={progress}
                          max="100"
                        ></progress>
                      )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`content-container flex pb-12 tabs-section h-[500px] bg-white ${ltr ? "pl-8" : "pr-8"}`}>
              <div className="text-content flex-1 relative">
                {selectedItem && (
                  <div className="font-bold break-words text-justify text-sm leading-7">
                    {itemsHeader[selectedIndex]} <br /><br />{" "}
                    {getContent(menuItems[selectedIndex])}
                    {getContentLink(menuItems[selectedIndex])}
                  </div>
                )}
              </div>
              <div className={"flex-1 justify-center pr-2 pl-2 relative video-tab-parent"}>
                {selectedItem && (
                  <>
                    {isLoading && (
                      <div className={`videos-loader absolute ${!ltr ? "left-[-2rem]" : "right-[-2rem]"} rounded-lg w-full h-[85%] flex items-center justify-center z-50`}>
                        <div className="custom-loader"></div>{/* Replace with your custom loader component */}
                      </div>
                    )}
                    <video
                      key={menuItems[selectedIndex]}
                      ref={videoRef}
                      className={`tabs_video ${ltr ? "tabs_video_ltr" : null} mx-auto absolute bg-[#F3F4F6]`}
                      autoPlay
                      playsInline
                      muted
                      loop={false} // Ensure loop is set to false
                    >
                      <source
                        src={getVideoSrc(menuItems[selectedIndex], language)}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}
                {/* {selectedItem && (

                  <video key={menuItems[selectedIndex]} ref={videoRef} style={ltr ? {right : "-6rem"} : {}} className="tabs_video mx-auto absolute bg-[#F3F4F6]" autoPlay playsInline loop muted>
                    <source src={getVideoSrc(menuItems[selectedIndex] , language)} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                )} */}
              </div>
            </div>
            <div className="tab_button flex items-center justify-center py-8">
              <Link
                  className="bg-green hover:bg-[#018767] p-3 px-4"
                  href={`/${language}/contact-us#form`}
                >{translate('video.btn' , language)}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMenu;