import { useState, useEffect } from 'react';
import { useLanguage } from '@/context/LanguageContext';

export const useLanguageSetup = () => {
  const { language, isLanguageInitialized } = useLanguage();
  const initialLtr = language === "en" || language === "tr";
  const [ltr, setLtr] = useState(initialLtr);

  useEffect(() => {
    if (isLanguageInitialized) {
      setLtr(language === "en" || language === "tr");
    }
  }, [language, isLanguageInitialized]);

  return { ltr, language, isLanguageInitialized };
};
