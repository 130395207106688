// "use client";

import { useState, useEffect } from "react";
import Image, { StaticImageData } from "next/image";
import styles from "./Carousel.module.css";
import { useLanguage } from "@/context/LanguageContext";

//fa imgs
import shipImg from "../../../public/icons/fa/slide1.png";
import treeImg from "../../../public/icons/fa/slide2.png";
import shipImgFa from "../../../public/icons/fa/slide3.png";

//en imgs
import chitaImgEn from "@/public/icons/en/slide1.png";
import treeImgEn from "@/public/icons/en/slide2.png";
import shipImgEn from "@/public/icons/en/slide3.png";

//ar imgs
import chitaImgAr from "@/public/icons/ar/slide1.png";
import treeImgAr from "@/public/icons/ar/slide2.png";
import shipImgAr from "@/public/icons/ar/slide3.png";

//tr imgs
import chitaImgTr from "@/public/icons/tr/slide1.png";
import treeImgTr from "@/public/icons/tr/slide2.png";
import shipImgTr from "@/public/icons/tr/slide3.png";
import Head from "next/head";

const Carousel = () => {
  const { language } = useLanguage();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [images, setImages] = useState<StaticImageData[]>([]);

  useEffect(() => {
    let imgArray: StaticImageData[] = [];
    if (language === "fa") {
      imgArray = [shipImg, treeImg, shipImgFa];
    } else if (language === "en") {
      imgArray = [chitaImgEn, treeImgEn, shipImgEn];
    } else if (language === "ar") {
      imgArray = [chitaImgAr, treeImgAr, shipImgAr];
    } else if (language === "tr") {
      imgArray = [chitaImgTr, treeImgTr, shipImgTr];
    }
    setImages(imgArray);
    setCurrentSlide(0); // Reset to first slide when language changes
  }, [language]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change every 5 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  return (
    <div
      id="default-carousel"
      className={`${styles.mainCarousel} relative w-full`}
      data-carousel="slide"
    >
      <Head>
        <link rel="preload" href={"@/public/icons/fa/slide1.png"} as="image" />
      </Head>
      <div
        style={{ width: "100%" }}
        className="relative overflow-hidden rounded-lg md:h-[23.5rem] aspect-w-16 aspect-h-9"
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`duration-700 ease-in-out carousel-slide ${
              index === currentSlide ? "" : "hidden"
            }`}
            data-carousel-item
          >
            <Image
              src={image}
              alt={`Slide ${index + 1} - bina-main-intro`}
              priority
            />
          </div>
        ))}
      </div>
      {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
    </div>
  );
};

export default Carousel;
