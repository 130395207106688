"use client";
import * as React from 'react';
import "./announceBar.css";

import { useLanguage } from "@/context/LanguageContext";
import {translate} from "@/Hooks/translate";

const AnnounceBar = () => {

  const {language } = useLanguage();

    return(
        <div className='w-full main-bar'>
            <div className='max-w-screen-xl mx-auto px-8 mb-12'>
                <div className='w-[100%] h-[270px]  rounded-[15px] flex text-white p-8'>
                    <div className="w-[70%] p-4 flex flex-col justify-center">
                        <h3 className='text-[2rem]'>{translate('announce.title' , language)}</h3>
                        <p className='text-[1.1rem] mt-4 leading-8'>{translate('announce.text' , language)}</p>
                    </div>
                    <div className="w-[30%] flex items-center justify-center">
                        <button
                            className="border-[2px] text-black bg-white hover:bg-[#e8e8e8] p-4 rounded-[10px]"
                            onClick={() => window.location.href = `/${language}/hub`}
                            >
                            {translate('announce.btn' , language)} 
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AnnounceBar;