"use client";
import Carousel from './Carousel';
import backgroundImagePath from "../../../public/icons/header4.png";
import "./herosection.css"
import Image from 'next/image';

import {translate} from "@/Hooks/translate";
import { useLanguageSetup } from '@/Hooks/useLanguageSetup';
import Link from 'next/link';

import Icon from '@mdi/react';
import { mdiArrowLeftThin , mdiArrowRightThin } from '@mdi/js';
import Head from 'next/head';

export default function HeroSection() { 

  const { ltr, language, isLanguageInitialized } = useLanguageSetup();

  if (!isLanguageInitialized) {
      return null; // or a loading spinner
  }

  const imageStyle = {
    transform: `scaleX(${ltr ? "-1" : "1"})`,
  };

  return (
    <div>
      <Head>
        <link rel="preload" href={"@/public/icons/header4.png"} as="image" />
      </Head>
      <div
        className="bg-center bg-no-repeat bg-cover"
        style={{
          // backgroundImage: `url(${backgroundImagePath})`,
          // backgroundColor: "#F3F8FC", // رنگ زمینه پشت تصویر (fallback),
          backgroundRepeat : "no-repeat" ,
          backgroundSize : "contain",
          width : "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={backgroundImagePath} alt='main-banner-background-image' className="hero-background" style={imageStyle} priority />
        <div className="px-8 mx-auto max-w-screen-xl text-center py-20 lg:py-52 hero-mobile">
          <div style={{width : "100%"}} className="flex justify-between space-y-8 md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-start hero">
            <div className="w-full md:w-1/2 sm:text-center   " style={{zIndex : "2"}}>
              <h3 style={ltr ? {textAlign : "left" , lineHeight : "3rem"} : {textAlign : "right"}} className="font-bold md:text-4xl mb-6 sm:text-3xl pt-3    ">{translate('hero.title' , language)}</h3>
              <h4 style={ltr ? {textAlign : "left"} : {textAlign : "right"}} className="font-bold md:text-[1.2rem] mb-1 sm:text-[1rem] mt-4 pt-3">{translate('hero.second_title' , language)}</h4>
              <p className="md:text-2xl sm:text-xl mb-4" style={{textAlign : "justify"}}>{translate('hero.text' , language)}</p>
              <div className={`flex justify-between text-[#EF539E] relative hover-effect transition-all duration-300 ease-in-out pb-1 ${ltr ? "ltr-border w-[390px]" : "rtl-border w-[300px]"}`}>
                <Link href={"https://app.binaexperts.com/login"} className='pb-1' target='_blank'>{translate('hero.link', language)}</Link>
                {ltr ? <Icon path={mdiArrowRightThin} size={1} /> : <Icon path={mdiArrowLeftThin} size={1} />}
              </div>
            </div>
            <div style={ltr ? {marginRight : "0" , justifyContent : "flex-end"} : {marginRight : "0" , textAlign : "right"}} className="w-full md:w-1/2 flex justify-end items-center mt-8 md:mt-0">
              <Carousel />
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
